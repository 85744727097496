<template>
  <div>
    <svg ref="gantt" />
  </div>
</template>

<script>
import Gantt from "frappe-gantt";

export default {
  name: "FrappeGantt",
  props: {
    viewMode: {
      type: String,
      required: false,
      default: "month",
    },
    tasks: {
      type: Array,
      required: true,
      default: (() => [])(),
    },
  },
  data() {
    return {
      gantt: {},
    };
  },
  watch: {
    viewMode() {
      this.updateViewMode();
    },

    tasks() {
      this.setupGanttChart();

      this.updateTasks();
    },
  },
  mounted() {
    this.setupGanttChart();
  },
  methods: {
    setupGanttChart() {
      this.gantt = new Gantt(this.$refs.gantt, this.tasks, {
        on_click: (task) => {
          this.$emit("task-updated", task);
        },

        on_date_change: (task, start, end) => {
          this.$emit("task-date-updated", task, start, end);
        },

        on_progress_change: (task, progress) => {
          this.$emit("task-progress-updated", task, progress);
        },

        //I doubt you will ever need this as the developer already knows what view mode they set.
        on_view_change: (mode) => {
          this.$emit("view-mode-updated", mode);
        },

        month_names: {
          fr: [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "May",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
          ],
        },
        language: "fr",
        header_height: 50,
        column_width: 30,
        step: 24,
        view_modes: ["Day", "Week", "Month", "Year"],
        bar_height: 20,
        bar_corner_radius: 3,
        arrow_curve: 5,
        padding: 18,
        view_mode: "Day",
        date_format: "DD/MM/YYYY",
      });

      this.updateTasks();
      this.updateViewMode();
    },

    updateViewMode() {
      this.gantt.change_view_mode(
        this.viewMode[0].toUpperCase() + this.viewMode.substring(1)
      );
    },

    updateTasks() {
      this.gantt.refresh(this.tasks);
    },
  },

  //    updated: function () {
  //   let progresses = document.querySelectorAll(".bar-progress");
  //   if (progresses.length > 0) {
  //     progresses.forEach((it) => {
  //       var avancement = parseInt(it.parentElement.lastElementChild.innerHTML.split("%")[0])
  //       var color =
  //         avancement > 20 && avancement <= 70
  //           ? "#f5f245"
  //           : avancement > 70
  //           ? "#23d92c"
  //           : "#f5ba45";

  //       it.style.fill = color;
  // });
  //   }
  // },
};
</script>

<style lang="scss">
@import "~frappe-gantt/dist/frappe-gantt.css";
</style>
